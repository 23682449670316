<template>
    <div class="box">
        <!-- banner -->
        <banner></banner>
        <!-- 为什么参展 -->
        <div class="why_cz">
            <h1 class="title" >{{ $t('wsmcz_title_off') }}</h1>
            <div class="why_box">
                <div class="item" v-for="(item,key) in why_cz_list" :key='key'>
                    <img :src="item.url" alt="">
                    <span>{{item.lable}}</span>
                </div>
            </div>
        </div>
        <!-- 参展流程 -->
        <div class="why_cz" style="padding-top:60px;">
            <h1 class="title"  style="padding-bottom:34px;">{{$t('off_czlc')}}</h1>
            <ExhibitionProcess ></ExhibitionProcess>
        </div>
        <!-- 参展费用 -->
        <div class="czfy">
            <h1 class="title">{{ $t('off_czfy') }}</h1>
            <table>
                <thead>
                    <th colspan="3">{{$t('time_before_1_30')}}</th>
                    <th>{{$t('time_after_1_30')}}</th>
                    <th>{{$t('quanyi')}}</th>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="2" class="stand_aa">
                              {{ $t('off_qtmp') }}<span><i class="el-icon-caret-bottom" style="font-size:10px"></i></span> 
                        </td>
                        <td  class="text member_text_color">{{$t('AlreadyMember')}}</td>
                        <td>
                            <div class="money_box">
                                <div class=""><span class="member_text_color money_code">¥</span><span class="member_text_color number_b">1600</span><span class="member_text_color money_unit">RMB</span></div>
                                <div><span class="member_text_color money_code">$</span><span class="member_text_color number_b">245</span><span class="member_text_color money_unit">USD</span></div>
                            </div>
                        </td>
                        <td>
                            <div class="money_box">
                                <div class=""><span class="member_text_color money_code">¥</span><span class="member_text_color number_b">1800</span><span class="member_text_color money_unit">RMB</span></div>
                                <div><span class="member_text_color money_code">$</span><span class="member_text_color number_b">270</span><span class="member_text_color money_unit">USD</span></div>
                            </div>

                        </td>
                        <td rowspan="2"  class="content_1">
                            <div v-for="(item,i) in stand_arr" :key='i'>{{ item }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text grey_text_color">{{$t('not_AlreadyMember')}}</td>
                        <td>
                            <div class="money_box">
                                <div class=""><span class="grey_text_color money_code">¥</span><span class="grey_text_color number_b">1800</span><span class="grey_text_color money_unit">RMB</span></div>
                                <div><span class="grey_text_color money_code">$</span><span class="grey_text_color number_b">270</span><span class="grey_text_color money_unit">USD</span></div>
                            </div>
                        </td>
                        <td>
                            <div class="money_box">
                                <div class=""><span class="grey_text_color money_code">¥</span><span class="grey_text_color number_b">2000</span><span class="grey_text_color money_unit">RMB</span></div>
                                <div><span class="grey_text_color money_code">$</span><span class="grey_text_color number_b">300</span><span class="grey_text_color money_unit">USD</span></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="3" class="left_base left1">
                          {{ $t('off_bzzw') }}<span><i class="el-icon-caret-bottom" style="font-size:10px"></i></span> 
                        </td>
                        <td style="width:160px;height:121px" class="text member_text_color">{{$t('AlreadyMember')}}</td>
                        <td style="width:325px" class="number">
                            <div class="money_box">
                                <div class=""><span class="member_text_color money_code">¥</span><span class="member_text_color number_b">8000</span><span class="member_text_color money_unit">RMB</span></div>
                                <div><span class="member_text_color money_code">$</span><span class="member_text_color number_b">1200</span><span class="member_text_color money_unit">USD</span></div>
                            </div>
                        </td>
                        <td style="width:336px" class="number member_text_color">
                            <div class="money_box">
                                <div><span class="member_text_color money_code">¥</span><span class="member_text_color number_b">10000</span><span class="member_text_color money_unit">RMB</span></div>
                                <div><span class="member_text_color money_code">$</span><span class="member_text_color number_b">1350</span><span class="member_text_color money_unit">USD</span></div>
                            </div>
                        </td>
                        <td rowspan="2" class="content_1">
                            <div>{{$t('off_tb_msg1')}}</div>
                            <div>{{$t('off_tb_msg2')}}</div>
                            <div>{{$t('off_tb_msg3')}}</div>
                            <div>{{$t('off_tb_msg4')}}</div>
                            <div>{{$t('off_tb_msg5')}}</div>
                            <div>{{$t('off_tb_msg6')}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text grey_text_color" style="height:119px">{{$t('not_AlreadyMember')}}</td>
                        <td class="number grey_text_color">
                            <div class="money_box number">
                                <div> 
                                    <span class="grey_text_color money_code">¥</span>
                                    <span class="grey_text_color number_b">10000</span>
                                    <span class="grey_text_color money_unit">RMB</span>
                                </div>
                                <div>
                                    <span class="grey_text_color money_code">$</span>
                                    <span class="grey_text_color number_b">1350</span>
                                    <span class="grey_text_color money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                        <td class="number grey_text_color">
                            <div class="money_box">
                                <div>
                                    <span class="grey_text_color money_code">¥</span>
                                    <span class="grey_text_color number_b">12000</span>
                                    <span class="grey_text_color money_unit">RMB</span>
                                </div>
                                <div>
                                    <span class="grey_text_color money_code">$</span>
                                    <span class="grey_text_color number_b">1500</span>
                                    <span class="grey_text_color money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text" style="height:116px">{{ $t('off_xhxxzt') }}</td>
                        <td class="number">
                            <div class="money_box">
                                <div>
                                    <span class="money_code">¥</span>
                                    <span class="number_b">24000</span>
                                    <span class="money_unit">RMB</span>
                                </div>
                                <div>
                                    <span class="money_code">$</span>
                                    <span class="number_b">3600</span>
                                    <span class="money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                        <td class="number">
                            <div class="money_box">
                                <div>
                                    <span class="money_code">¥</span>
                                    <span class="number_b">24000</span>
                                    <span class="money_unit">RMB{{$t('start')}}</span>
                                </div>
                                <div>
                                    <span class="money_code">$</span>
                                    <span class="number_b">3600</span>
                                    <span class="money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                        <td class="content_2">
                            <div>{{$t('off_tb_msg7')}}</div>
                            <div>{{$t('off_tb_msg8')}}</div>
                            <div>{{$t('off_tb_msg9')}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="2" class="left_base left2">{{$t('Minibooth')}}<span><i class="el-icon-caret-bottom" style="font-size:10px"></i></span> </td>
                        <td class="text member_text_color">{{$t('AlreadyMember')}}</td>
                        <td class="number">
                            <div class="money_box">
                                <div class="member_text_color">-</div>
                                <div>
                                    <span class="member_text_color money_code">$</span>
                                    <span class="number_b member_text_color">800</span>
                                    <span class="member_text_color money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                        <td class="number">
                            <div class="money_box">
                                <div class="member_text_color">-</div>
                                <div>
                                    <span class="member_text_color money_code">$</span>
                                    <span class="number_b member_text_color">900</span>
                                    <span class="member_text_color">USD</span>
                                </div>
                            </div>
                        </td>
                        <td rowspan="2" class="content_3">
                            <div>{{ $t('off_tb_msg10') }}</div>
                            <div>{{ $t('off_tb_msg11') }}</div>
                        </td>
                    </tr>
                    <tr>
                       <td class="text grey_text_color">{{$t('not_AlreadyMember')}}</td>
                       <td class="number grey_text_color">
                            <div class="money_box">
                                <div>-</div>
                                <div>
                                    <span class="grey_text_color money_code">$</span>
                                    <span class="number_b grey_text_color">900</span>
                                    <span class="grey_text_color money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                       <td class="number grey_text_color">
                            <div class="money_box">
                                <div>-</div>
                                <div>
                                    <span class="grey_text_color money_code">$</span>
                                    <span class="number_b grey_text_color">1000</span>
                                    <span class="grey_text_color money_unit">USD</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td  class="left_base left3">{{$t('Specialbooth')}}<span><i class="el-icon-caret-bottom" style="font-size:10px"></i></span> </td>
                        <td></td>
                        <td class="number">
                            <div class="money_box">
                                <div>
                                    <span class=" money_code">¥</span>
                                    <span class="number_b" style="color:#1F292E">28800</span>
                                    <span  class="money_unit">RMB/36㎡</span>
                                </div>
                                <div>
                                    <span class=" money_code">$</span>
                                    <span class="number_b" style="color:#1F292E">4600</span>
                                    <span  class="money_unit">USD/36㎡</span>
                                </div>
                            </div>
                        </td>
                        <td class="number">
                            <div class="money_box">
                                <div>
                                    <span class=" money_code">¥</span>
                                    <span class="number_b" style="color:#1F292E">36000</span>
                                    <span  class="money_unit">RMB/36㎡</span>
                                </div>
                                <div>
                                    <span class=" money_code">$</span>
                                    <span class="number_b" style="color:#1F292E">5780</span>
                                    <span  class="money_unit">USD/36㎡</span>
                                </div>
                            </div>
                         </td>
                        <td class="content_4">
                            <div>{{  $t('off_tb_msg12') }}</div>
                            <div>{{  $t('off_tb_msg13') }}</div>
                            <div>{{  $t('off_tb_msg14') }}</div>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 成为赞助商 -->
        <div class="cwzzs" style="padding-top:60px">
            <h1 class="title">{{ $t('off_cwzzs') }}</h1>
            <div class="lable">
                <span class="lable_t">{{ $t('off_djgm') }}</span>
                <div>
                    <p style="padding-right:15px">
                        <span class="member_text_color">¥</span>
                        <span class="member_text_color number_b">100000</span>
                        <span class="member_text_color">RMB</span>
                    </p>
                    <p>
                        <span class="member_text_color">$</span>
                        <span class="member_text_color number_b">15000</span>
                        <span class="member_text_color">USD</span>
                    </p>
                </div>
            </div>
            <!-- 线上权益  线下权益 左右两块内容 -->
            <div class="content">
                <div class="l_r_box"  v-for="(item,index) in qy_msg" :key="index" :class="{isLeft:index==0}" >
                    <div class="c_header">
                        <img :src="item.header.img" alt="">
                        <h1>{{item.header.lable}}</h1>
                    </div>
                    <div class="c_box">
                        <div class="c_item" v-for="(msg,m) in item.msg" :key="m">
                            <div>
                                <i class="el-icon-check"></i>
                            </div>
                            <span>{{msg}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 大图 -->
            <div style="padding-top:46px">
                <img :src="bigImg" alt="">
            </div>
            <!-- 海外展区冠名 && 晚宴冠名 -->
            <div v-for="(item,index) in hwzq_wy" :key="index" class="big_box">
                <div class="title">
                    <h1>{{item.title}}</h1>
                    <div>
                        <span class="">{{item.fh}}</span>
                        <span class="">{{item.money}}</span>
                        <span class="">{{item.code}}</span>
                    </div>
                </div>
                <img :src="LOCALE=='en'?dujiaImg_en:dujiaImg" alt="" style="width: 180px;">
                <div class="two_box">
                    <div  class="two_left">
                        <div class="c_item" v-for="(msg,m) in item.msg" :key="m">
                            <div>
                                <i class="el-icon-check"></i>
                            </div>
                            <span>{{msg}}</span>
                        </div>
                    </div>
                    
                    <div class="two_right" :class="{noimg:item.img==''}" >
                        <img :src="item.img" alt="">
                    </div>
                </div>
                
                
            </div>
        </div>
        <!-- 查看更多 -->
        <div class="look_more">
            <el-button class="more" @click="companyLookMore" >{{$t('Learn_more')}}</el-button>
        </div>
        
    </div>
</template>
<script>
import banner from '~exp/components/common/banner'
import ExhibitionProcess from '~exp/components/common/Exhibition_process'
export default {
    components:{
        banner,ExhibitionProcess
    },
    data(){
        return{
            //
            stand_arr:[
                this.$t('stand_qy_1'),
                this.$t('stand_qy_2'),
                this.$t('stand_qy_3'),
                this.$t('stand_qy_4'),
                this.$t('stand_qy_5'),
                this.$t('stand_qy_6'),
                this.$t('stand_qy_7'),
            ],
            //为什么报名线上展会
            why_cz_list:[
                {
                    lable:this.$t('off_gnzq'),
                    url:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/gnzq.png'
                },
                {
                    lable:this.$t('off_eve'),
                    url:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/yvy.png'
                },
                {
                    lable:this.$t('off_zxdjh'),
                    url:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/zxdjh.png'
                },
                {
                    lable:this.$t('off_hywy'),
                    url:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/hywy.png'
                },
                {
                    lable:this.$t('off_hwzq'),
                    url:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/hwzq.png'
                }
            ],
            // 线上权益线下权益的数据
            qy_msg:[
                {
                    header:{
                        lable:this.$t('off_qunayi'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/xxqy.png',
                    },
                    msg:[  
                        this.$t('off_qunayi_item1'),
                        this.$t('off_qunayi_item2'),
                        this.$t('off_qunayi_item3'),
                        this.$t('off_qunayi_item4'),
                        this.$t('off_qunayi_item5'),
                        this.$t('off_qunayi_item6'),
                        this.$t('off_qunayi_item7'),
                        this.$t('off_qunayi_item8'),
                    ],
                },
                {
                    header:{
                        lable:this.$t('on_quanyi'),
                        img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/xsqy.png',
                    },
                    msg:[
                        this.$t('on_quanyi_item1'),
                        this.$t('on_quanyi_item2'),
                        this.$t('on_quanyi_item3'),
                        this.$t('on_quanyi_item4'),
                        this.$t('on_quanyi_item5'),
                        this.$t('on_quanyi_item6')
                    ]
                }
                
            ],
            bigImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/banner.png',
            //海外展区冠名 && 晚宴冠名
            dujiaImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/dujia.png',
            dujiaImg_en:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/dujia.jpg',
            hwzq_wy:[
                {
                    title:this.$t('off_hwzq_title'),
                    fh:"$",
                    money:"7700",
                    code:"USD",
                    img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/gaiwaiqu.png',
                    msg:[
                        this.$t('off_hwzq_tiem1'),
                        this.$t('off_hwzq_tiem2'),
                        this.$t('off_hwzq_tiem3'),
                        this.$t('off_hwzq_tiem4'),
                        this.$t('off_hwzq_tiem5'),
                        this.$t('off_hwzq_tiem6'),
                    ]
                },
                {
                    title:this.$t('off_wygm_title'),
                    fh:this.LOCALE=='zh'?"¥":"$",
                    money:this.LOCALE=='zh'?"35000":"5200",
                    code:this.LOCALE=='zh'?"RMB":"USD",
                    img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/banquet.png',
                    msg:[
                        this.$t('off_wygm_item1'),
                        this.$t('off_wygm_item2'),
                        this.$t('off_wygm_item3'),
                        this.$t('off_wygm_item4'),
                        this.$t('off_wygm_item5'),
                        this.$t('off_wygm_item6'),
                        this.$t('off_wygm_item7'),
                        this.$t('off_wygm_item8')
                    ]
                }
            ]
        }
    },
    methods:{
        companyLookMore(){
            this.$router.push({path:'/sponsorChance'})
        }
    }
}
</script>
<style lang="less" scoped>
    .stand_aa{
        background: #4b5cc4;
        font-size: 21px;
        font-weight: 800;
        color: #FFFFFF;
    }
    .why_cz{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 28px;
        .title{   
            font-size: 24px;
            font-weight: 800;
            color: #333333;
            line-height: 33px;
        }
        .why_box{
            padding-top: 24px;
            display: flex;
            justify-content: space-between;
            width: 1300px;
            .item{
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    width: 131px;
                    height: 131px;
                }
                span{
                    display: block;
                    padding-top: 16px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 25px;
                }
            }
        }
    }
    .czfy{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top:60px;
        .title{   
            font-size: 24px;
            font-weight: 800;
            color: #333333;
            line-height: 33px;
            padding-bottom: 34px;
        }
        table{
            width: 1300px;
            thead{  
                height: 58px;
                background: #F7F7F7;
                line-height: 58px;
                font-size: 18px;
                font-weight: 800;
                color: #1F292E;
            }
            .left_base{
                width: 145px;
                font-size: 21px;
                font-weight: 800;
                color: #FFFFFF;
                span{
                    display: inline-block;
                    position: relative;
                    i{
                        position: absolute;
                        top: -11px;
                        left: 2px;
                    }
                }
            }
            
            .left1{
                height: 358px;
                background: #2292FF;
            }
            .left2{
                height:241px;
                background: #68B4FF;
            }
            .left3{
                height:242px;
                background: #FF9A8F;
            }
            tr td{
                border: 1px solid #E2E2E2;
            }
            .text{
                font-size: 18px;
                font-weight: 800;
            }
            .number{
                font-size: 14px;
                font-weight: 800;
            }
            .number_b{
                font-size: 18px;
                font-weight: 800;
                display: inline-block;
                padding-right: 6px;
            }
            .member_text_color{
                color:#DD9740;
            }
            .grey_text_color{
                color: #677B85;
            }
            .money_box{
                display: flex;
                justify-content: space-around;
            }
            .content_1{
                padding: 16px 20px 15px 29px;
                text-align: left;
                font-size: 14px;
            }
            .content_2{
                padding: 24px 19px 4px 29px;
                text-align: left;
                font-size: 14px;
            }
            .content_3{
                 padding: 24px 19px 4px 29px;
                text-align: left;
                font-size: 14px;
            }
            .content_4{
                text-align: left;
                font-size: 14px;
                padding: 62px 19px 76px 29px;
            }
        }
    }
    .cwzzs{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .title{   
            font-size: 24px;
            font-weight: 800;
            color: #333333;
            line-height: 33px;
            padding-bottom: 34px;
        }
        .lable{
            display: flex;
            .lable_t{
                width: 174px;
                height: 25px;
                font-size: 18px;
                font-weight: 800;
                color: #333333;
                line-height: 25px;
                margin-right: 30px;
            }
            div{
                display: flex;
                p span{
                    color: #DD9740;
                }
                .number_b{
                    font-size: 18px;
                    font-weight: 800;
                    display: inline-block;
                    padding-right: 6px;
                }
                .member_text_color{
                    color:#DD9740;
                }
            }
        }
        .content{
            display: flex;
            padding-top:55px;
            .l_r_box{
                width: 648px;
                padding-left: 118px;
            }
            .isLeft{
                border-right: 1px solid #D8D8D8;;
            }
            .c_header{
                display: flex;
                align-items: center;
                h1{
                    padding-left: 14px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #1F292E;
                }
            }
        }
    }
    // 
    .big_box{
        padding-top: 125px;
        .title{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 11px;
            h1{
                font-size: 18px;
                font-weight: 800;
                color: #333333;
                padding-right: 15px;
            }
            div>span{
                color: #DD9740;
            }
            div>span:first-child{
                font-size: 14px;
            }
            div>span:nth-child(2){
                font-size: 18px;
                font-weight: 800;
                display: inline-block;
                padding-right: 6px;
            }
            div>span:nth-child(3){
                font-size: 14px;
            }
        }
        .two_box{
            display: flex;
            padding-top: 36px;
            .noimg{
                width: 696px;
                height: 261px;
                background: #D8D8D8;
            }
            .two_left{
                width: 604px;
            }
            
        }
    }
    .look_more{
        display: flex;
        justify-content: center;
        padding-top:40px;
        padding-bottom: 70px;
        .more{
            height: 40px;
            width: 180px;
        }
    }

    /* 公共的样式 */
    .c_item{
        display: flex;
        padding-bottom: 20px;
        div{
            position: relative;
            width: 18px;
            height: 13px;
            i{
                color: #28CD1E;
                font-weight: bold;
                position:absolute;
                top: 5px;
            }
        }
        span{
            font-size: 14px;
            font-weight: 400;
            color: #1F292E;
            line-height: 20px;
            text-align: left;
            width: 417px;
            margin-left: 17px;
        }
    }
    .money_code{
        font-size: 14px;
        font-weight: 800;
    }
    .money_unit{
        font-size: 12px;
        font-weight: 400;
    }
</style>