<template>
    <div class="bg_style" :style="{ 'background-image': 'url(' + bg + ')' }" >
        <div class="item"><span class="span1" :title="items[0]">{{ items[0] }}</span></div>
        <div class="item "><span class="span2" :title="items[1]">{{ items[1] }}</span></div>
        <div class="item "><span class="span3" :title="items[2]">{{ items[2] }}</span></div>
        <div class="item "><span class="span4" :title="items[3]">{{ items[3] }}</span></div>
        <div class="item "><span class="span5" :title="items[4]">{{ items[4] }}</span></div>
    </div>     
</template>
<script>

export default {
    data(){
        return{
            bg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/offline/czlc_bg.png',
            items:[
                this.$t('czlc_item_1'),
                this.$t('czlc_item_2'),
                this.$t('czlc_item_3'),
                this.$t('czlc_item_4'),
                this.$t('czlc_item_5'),
            ]
        }
        
    }
}
</script>
<style lang="less" scoped>
    .bg_style{
        width: 1300px;
        height: 250px;
        background-size: 1300px 250px;
        background-repeat: no-repeat;
        display: flex;
        justify-content:space-evenly;
        align-items: flex-end;
        .item{
            cursor: pointer;
            width: 200px;
            height:130px;
            // border: 1px solid red;
            font-size: 24px;
            font-weight: 800;
            color: #FFFFFF;
            padding: 5px;
            position: relative;
            span{
                position: absolute;
                // border: 1px solid red;
                display: inline-block;
                width: 194px;
                height: 95px;
                left: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .span1{
                left:0px;
            }
            .span2{
                left: 2px;
            }
            .span4{
                top: -13px;
            }
            .span5{
                left: 8px;
                width: 194px;
            }
        }
    }
</style>